import React from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '@components/Loading';
import ClubTabsLayout from '@presenters/web/components/ClubTabs/ClubTabsLayout';

import {
  ROTARACT_FINANCE_MENU_NAME,
  ROTARY_FINANCE_MENU_NAME,
} from '@domain/clubs/constants';

import { isRotaractClub } from '@utils/isRotaractClub';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';

interface ClubFinanceProps extends RouteComponentProps {
  clubType: string;
}

const ClubFinance: React.FC<ClubFinanceProps> = ({ clubType }) => {
  const { t, i18n } = useTranslation();

  const menuName = isRotaractClub(clubType)
    ? ROTARACT_FINANCE_MENU_NAME
    : ROTARY_FINANCE_MENU_NAME;

  const { data: financeData, loading } = useMenu(
    menuName,
    i18n.language,
    true,
    false
  );

  if (loading) return <Loading />;

  return (
    <ClubTabsLayout
      tabHeader={t('club-details.menu.administration.finances', 'Finances')}
      data={financeData}
      classes="mobile-m:w-70 tablet:w-92 desktop:w-92"
    />
  );
};

export default ClubFinance;
